.header-icon {
	height: 50px;
	width: 50px;
	padding: 20px 0 10px 20px;
}

#sidebar-item:hover {
	background-color: var(--sidebar-hover-item);
}

.sidebar-item-container {
	display: flex;
	padding: 15px 20px;
	text-decoration: none;
}

.gradient-background {
	background:linear-gradient(transparent 61%, rgba(53,68,101,1) 100%);
}